import { SavePayload, useSaveDealsMutation } from '@/api/deals.api';
import { getUpdatedInputsAll, isBlotterReadOnly } from '@/features/blotter/blotterSlice';
import { Product } from '@/features/vacation/vacationModel';
import { useAppSelector } from '@/hooks/reduxHook';
import { getUpdatedInput } from '@/utils/updateUnput';
import { useSgwtWidgets } from '@sgwt/sgwt-widgets-react';
import { useCallback } from 'react';
import { FormattedMessage } from 'react-intl';

type SaveButtonProps = {
  deals: SavePayload[];
  overrideClassName?: string;
  isFromBulkAction?: boolean;
};

function SaveButton({ deals, overrideClassName, isFromBulkAction }: SaveButtonProps) {
  const updatedInputsAll = useAppSelector((state) => getUpdatedInputsAll(state));
  const blotterReadOnly = useAppSelector(isBlotterReadOnly);
  const { sgwtWebAnalytics } = useSgwtWidgets();

  const [saveDeals, { isLoading }] = useSaveDealsMutation();

  const onSave: React.FormEventHandler<HTMLButtonElement> = useCallback(
    async (event) => {
      sgwtWebAnalytics?.trackEvent(isFromBulkAction ? 'Bulk Save' : 'Save', 'User actions');
      const savePayload: SavePayload[] = deals.map((deal) => {
        const updatedInput = getUpdatedInput(updatedInputsAll, deal.dealId);
        const { valueDate, ...RestOfTheDeal } = deal;
        return {
          ...RestOfTheDeal,
          proposedValueDate:
            (updatedInput?.['valueDate.proposedDate'] as string) ?? valueDate?.proposedDate,
          product: (updatedInput?.['product'] as Product) ?? deal.product,
          spotRate: {
            bid: (updatedInput?.['spotRate.bid'] as number) ?? deal.spotRate.bid,
            ask: (updatedInput?.['spotRate.ask'] as number) ?? deal.spotRate.ask,
          },
          swapPoint: {
            bid: (updatedInput?.['swapPoint.bid'] as number) ?? deal.swapPoint?.bid,
            ask: (updatedInput?.['swapPoint.ask'] as number) ?? deal.swapPoint?.ask,
          },
        };
      });
      event.preventDefault();
      await saveDeals(savePayload);
    },
    [saveDeals, deals, updatedInputsAll, sgwtWebAnalytics, isFromBulkAction],
  );

  return (
    <button
      role="button"
      data-e2e={`save-${deals.length !== 1 ? 'deals' : deals[0].dealId}`}
      className={overrideClassName ? overrideClassName : 'btn btn-md btn-discreet-primary'}
      disabled={blotterReadOnly}
      onClick={onSave}
    >
      {!isLoading ? (
        <FormattedMessage id="blotter.save" />
      ) : (
        <div data-e2e="spinner" className="spinner spinner-sm" />
      )}
    </button>
  );
}

export default SaveButton;
