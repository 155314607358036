import { Switcher } from '@/core/bootstrap/Switcher';
import { AccountCenter } from '@/core/sgwt/AccountCenter';
import { Theme, getTheme, setTheme } from '@/utils/theme';
import { useCallback, useState } from 'react';
import { FormattedMessage } from 'react-intl';
import { Link, NavLink, useLocation } from 'react-router-dom';

export function Header(): JSX.Element {
  const { pathname } = useLocation();

  return (
    <header className={`${pathname === '/' ? '' : 'border-bottom'} navbar`}>
      <div className="navbar-title">
        <Link className="navbar-title-link" to="/">
          <div className="navbar-logo">
            <svg viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
              <rect x="0" y="0" width="100%" height="50%" fill="var(--bs-socgen)"></rect>
              <rect x="0" y="50%" width="100%" height="50%" fill="#1D1D1B"></rect>
              <rect x="17%" y="46.5%" width="65%" height="7%" fill="white"></rect>
            </svg>
          </div>
          <div className="navbar-title-divider" />
          <div className="navbar-service-name">
            <FormattedMessage id="common.appName" />
          </div>
        </Link>
      </div>

      <div className="navbar-content">
        <ul className="navbar-navigation m-0">
          <li className="navbar-item">
            <NavLink
              className={({ isActive }: { isActive: boolean }) =>
                `${isActive ? 'active' : ''} navbar-link`
              }
              to={`/`}
            >
              FX Sessions
            </NavLink>
          </li>
        </ul>
      </div>

      <div className="navbar-toolbar">
        <ThemeSwitcher />
        <AccountCenter />
      </div>
    </header>
  );
}

function ThemeSwitcher(): JSX.Element {
  const [currentTheme, setCurrentTheme] = useState(getTheme);
  const changeTheme = useCallback((checked: boolean) => {
    const theme: Theme = checked ? 'DARK' : 'LIGHT';
    setTheme(theme);
    setCurrentTheme(theme);
  }, []);
  return (
    <Switcher
      id="dark"
      label={currentTheme}
      checked={currentTheme === 'DARK'}
      onChange={changeTheme}
    />
  );
}
