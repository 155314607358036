import { DateSelectedEvent, SgDatepickerHTMLElement } from '@/types/sg-date-picker';
import { isDefined, isNotDefined } from '@sgme/fp';
import { useCallback, useEffect, useRef, useState } from 'react';
import { Size } from '@sgme/ui';
import { getLocaleDatePattern, parseDateForLocale } from './utils';
import clsx from 'clsx';

import './datePicker.css';
import { formatDate, parseISO, subDays } from 'date-fns';

export type DatePickerType = 'multiple' | 'single';

export type DateCustomPresetClickEvent = CustomEvent<{
  preset: unknown;
  element: HTMLElement;
}>;

export interface SgDatePickerProps<Type extends DatePickerType = 'multiple'> {
  acceptEmptyValue?: boolean;
  className?: string;
  closeAfterSelect?: boolean;
  closeWhenClickOutside?: boolean;
  e2e?: string;
  format?: string;
  isDisabled?: boolean;
  isReadonly?: boolean;
  label?: string;
  maxValue?: Date | string;
  minValue?: Date | string;
  selectionType: Type;
  onChange: (value: string) => void;
  showClearButton?: boolean;
  size?: Size;
  validationMessage?: string;
  date?: string;
  values?: (Date | string)[];
  yearControllers?: boolean;
}

const BLOTTER_DATE_FORMAT = 'dd LLL yyyy';
/**
 * Wrap of the <sg-date-picker> component from SG Bootstrap 5.
 *
 * @example
 * <DatePicker size="lg" onChange={doAction}></DatePicker>
 *
 */
export default function SgDatePicker<Type extends DatePickerType>({
  acceptEmptyValue = false,
  className,
  closeAfterSelect = true,
  closeWhenClickOutside = true,
  e2e,
  format = BLOTTER_DATE_FORMAT,
  isDisabled = false,
  isReadonly = false,
  label,
  onChange,
  selectionType,
  showClearButton = false,
  size = 'md',
  date,
}: SgDatePickerProps<Type>) {
  const sgDatePickerRef = useRef<SgDatepickerHTMLElement>(null);
  const inputValue = useRef<string>('');
  const isDateInvalid = useRef<boolean>(false);
  const [isReady, setIsReady] = useState(false);

  const locale = navigator.language; // we use the browser configuration, and not the user configuration locale for the format of date
  const datePattern = getLocaleDatePattern(locale);

  const onDateSelected = useCallback(
    async (event: DateSelectedEvent) => {
      // do not trigger onChange if event has been raised programaticaly
      if (event.detail.isDateSelectedProgramaticaly) return;

      const currentSelection = (await sgDatePickerRef.current?.getSelection())?.at(0);
      const date = isDefined(currentSelection) ? formatDate(currentSelection, format) : undefined;

      if (isDefined(date) && !isDateInvalid.current) {
        onChange(date);
      }
      if (isDateInvalid.current) {
        isDateInvalid.current = false;
      }
    },
    [onChange, sgDatePickerRef.current?.getSelection],
  );

  const onInputChanged = useCallback((event: CustomEvent<string>) => {
    const value = event.detail;

    if (isNotDefined(value) || value === '') {
      return; // no change
    }

    inputValue.current = value;
  }, []);

  const updateDateFromPickerInput = useCallback(() => {
    const value = inputValue.current;
    inputValue.current = '';

    if (value === '') {
      return;
    }

    const newDate = parseDateForLocale(value, locale);

    if (isDefined(newDate)) {
      onChange(newDate);
    }
  }, [locale, onChange]);

  const onCloseCalendar = useCallback(() => {
    updateDateFromPickerInput();
  }, [updateDateFromPickerInput]);

  const onReady = useCallback((): void => {
    setIsReady(true);
  }, []);

  const invalidDateListener = () => {
    const sgDatePicker = sgDatePickerRef.current;
    if (isNotDefined(sgDatePicker)) {
      return;
    }
    const selectedDates = date ? [parseISO(date).getTime()] : [];
    isDateInvalid.current = true;
    sgDatePicker.setSelection(selectedDates);
  };

  useEffect(() => {
    const selectedDates = date ? [parseISO(date).getTime()] : [];

    if (isReady) {
      sgDatePickerRef.current?.setSelection(selectedDates, { isDateSelectedProgramaticaly: true });
    }
  }, [isReady]);

  useEffect(() => {
    const sgDatePicker = sgDatePickerRef.current;

    if (isNotDefined(sgDatePicker) || !isReady) {
      return;
    }

    const selectedDates = date ? [parseISO(date).getTime()] : [];

    sgDatePicker.addSelection(selectedDates);
  }, [date, isReady]);

  useEffect(() => {
    const sgDatePicker = sgDatePickerRef.current;

    if (isNotDefined(sgDatePicker)) {
      return;
    }

    sgDatePicker.addEventListener('ready', onReady);

    if (isReady) {
      sgDatePicker.addEventListener('invalidDate', invalidDateListener);
      sgDatePicker.addEventListener('dateSelected', onDateSelected);
      sgDatePicker.addEventListener('dateUnSelected', onDateSelected);
      sgDatePicker.addEventListener('inputChanged', onInputChanged);
      sgDatePicker.addEventListener('calendarClose', onCloseCalendar);
    }

    return () => {
      sgDatePicker.removeEventListener('invalidDate', invalidDateListener);
      sgDatePicker.removeEventListener('ready', onReady);
      sgDatePicker.removeEventListener('dateSelected', onDateSelected);
      sgDatePicker.removeEventListener('dateUnSelected', onDateSelected);
      sgDatePicker.removeEventListener('inputChanged', onInputChanged);
      sgDatePicker.removeEventListener('calendarClose', onCloseCalendar);
    };

    // WARNING: resubscribe when one of these method changes
    // which have to depend on inputValue
  }, [onDateSelected, onInputChanged, onCloseCalendar, onReady]);

  return (
    <sg-date-picker
      id={`sg-date-picker-${name}`}
      locale={locale}
      format={datePattern}
      placeholder={datePattern}
      accept-empty-value={acceptEmptyValue}
      classes={clsx(className)}
      close-after-select={closeAfterSelect}
      close-when-click-outside={closeWhenClickOutside}
      data-e2e={`${e2e}-date-picker`}
      disabled={isDisabled}
      display-format={format}
      item-selection={selectionType === 'multiple' ? 'multiple' : 'single'}
      labelled-by={label}
      readonly={isReadonly}
      ref={sgDatePickerRef}
      show-clear-button={showClearButton}
      size={size}
      disable-presets={`before:${subDays(new Date(), 1).getTime()}`}
    />
  );
}
