import { Deal, Product } from '@/features/vacation/vacationModel';
import SaveButton from './SaveButton';
import {
  DeletePayload,
  DiscardPayload,
  PrepareRefreshPayload,
  SavePayload,
  SendPayload,
} from '@/api/deals.api';
import RefreshButton from './RefreshButton';
import { useParams } from 'react-router-dom';
import RepriceButton, { RepriceProps } from './RepriceButton';
import { isNotDefined } from '@sgme/fp';
import { useAppSelector } from '@/hooks/reduxHook';
import { getUserSesameId } from '@/features/user/userSlice';
import SendButton from './SendButton';
import DeleteButton from './DeleteButton';
import { DiscardButton } from '@/components/booking/DealsActions/DiscardButton';
import { getUpdatedInputsAll } from '@/features/blotter/blotterSlice';
import { getUpdatedInput } from '@/utils/updateUnput';

type ActionButtons = Deal & { vacationId: string; currencyPairId: string };

export const ActionButtons = ({
  status,
  id,
  counterparty,
  spotRate,
  swapPoint,
  originalAmount,
  computedAmount,
  vacationId,
  currencyPairId,
  product,
  margin,
  marketRate,
  clientRate,
  valueDate,
}: ActionButtons) => {
  const { proposedDate } = valueDate;
  const { currency1, currency2 } = useParams();

  if (isNotDefined(currency1) || isNotDefined(currency2)) {
    throw new Error('Need currency pair');
  }

  const showRefresh = !['SENT', 'DONE'].includes(status);
  const showSave = !['SENT', 'DONE'].includes(status);
  const showDelete = status === 'SENT';
  const showRepriceAndSend = status === 'DONE';
  const showDiscard = status !== 'SENT';

  const dealOwner = useAppSelector((state) => getUserSesameId(state) ?? '');
  const updatedInputsAll = useAppSelector(getUpdatedInputsAll);
  const updatedInput = getUpdatedInput(updatedInputsAll, id);

  //todo - see with Cyril if we can rely safely on untouched original and computed Amount
  //answer - original and computed amount are just needed in the payload but no action on it
  const deal: SavePayload = {
    dealId: id,
    currencyPairId,
    currencyPair: `${currency1}${currency2}`,
    spotRate,
    swapPoint,
    originalAmount,
    computedAmount,
    vacationId,
    dealOwner: dealOwner.toUpperCase(),
    margin,
    marketRate,
    clientRate,
    product: (updatedInput?.['product'] as Product) ?? product,
    valueDate,
  };

  // @TODO ABO - SGEFX-5235: check if counter party should be add before
  const dealToSend: SendPayload = {
    ...deal,
    counterparty,
  };

  const dealToDiscard: DiscardPayload = {
    ...deal,
    counterparty,
  };

  const dealToDelete: DeletePayload = {
    dealId: id,
    vacationId,
    dealOwner: dealOwner.toUpperCase(),
    product: (updatedInput?.['product'] as Product) ?? product,
    currencyPairId,
  };

  const refreshDealPayload: PrepareRefreshPayload = {
    dealId: id,
    currencyPair: `${currency1}${currency2}`, // key use by the backend
    product: (updatedInput?.['product'] as Product) ?? product,
    proposedDate: (updatedInput?.['valueDate.proposedDate'] as string) ?? proposedDate,
    currency1,
    currency2,
    vacationId,
  };

  const repriceProps: RepriceProps = {
    currency1,
    currency2,
    dealId: id,
  };

  if (['UNSUCCESSFUL', 'DELETED'].includes(status)) {
    return null;
  }

  return (
    <>
      {showDiscard ? <DiscardButton deals={[dealToDiscard]} /> : null}
      {showRepriceAndSend ? (
        <>
          <RepriceButton deals={[repriceProps]} />
          <SendButton deals={[dealToSend]} />
        </>
      ) : null}
      {showRefresh ? <RefreshButton deals={[refreshDealPayload]} /> : null}
      {showSave ? <SaveButton deals={[deal]} /> : null}
      {showDelete ? <DeleteButton deals={[dealToDelete]} /> : null}
    </>
  );
};
