import { isNotDefined } from '@sgme/fp';
import { format, parse } from 'date-fns';

const API_DATE_FORMAT = 'yyyy-MM-dd';

const DATE_FORMAT_BY_TYPE_PART = {
  day: 'dd',
  month: 'MM',
  year: 'yyyy',
} as Record<keyof Intl.DateTimeFormatPartTypesRegistry, string>;

export function getLocaleDatePattern(locale: string) {
  return new Intl.DateTimeFormat(locale)
    .formatToParts()
    .filter(({ type }) => type === 'day' || type === 'month' || type === 'year')
    .map(({ type }) => DATE_FORMAT_BY_TYPE_PART[type])
    .join('/');
}

export const parseDateForLocale = (stringDate: string | undefined, locale: string) => {
  if (isNotDefined(stringDate)) {
    return undefined;
  }

  const dateFormat = getLocaleDatePattern(locale);

  try {
    return format(parse(stringDate, dateFormat, new Date()), API_DATE_FORMAT);
  } catch (error) {
    // not a date
    return undefined;
  }
};
